import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useKeycloak } from '@react-keycloak/web';

export function PrivateRoute({
  component: Component,
  roles,
  ...rest
}) {
  const [keycloak] = useKeycloak();
  // const profile = useSelector((state) => state.main.profile);
  // const valid = roles.some((rol) => rol === profile);
  const profile = '/farmaceutico';
  const valid = 'farmaceutico';
  // console.log('profile', profile);
  // conosle.log('valid', valid);
  const ValidComponent = valid
    ? Component
    : () => 'No tiene permisos para ver esta seccion';
  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak.authenticated ? (
          <ValidComponent {...props} />
        ) : (
          keycloak.login()
        )
      }
    />
  );
}
