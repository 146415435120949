import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NavBarVErsion from '../NavBarVersion';
import { ReactComponent as PuntoFarma } from './puntofarma.svg';

import {
  AppBar,
  Hidden,
  Toolbar,
  makeStyles,
  IconButton,
  Grid,
  Avatar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.light,
  },
  toolbar: {
    minHeight: 64,
    height: '20%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  avatar: {
    width: '35px',
    height: '35px',
  },
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar color="transparent" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            // className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Grid style={{ position: 'fixed', right: '0' }}>
          <Grid
            container
            style={{ marginLeft: '15px', marginRight: '15px' }}
          >
            <Avatar
              className={classes.avatar}
              alt="User"
              // key={imgKey}
              // src={
              //   'https://electronicssoftware.net/wp-content/uploads/user.png'
              // }
            />
            <PuntoFarma />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
