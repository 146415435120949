import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MSP from '../../../shared/resources/img/logoMSPBScorto.png';
import PTI from '../../../shared/resources/img/pti.png';

const useStyles = makeStyles((theme) => ({
  root: {},
  overview: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.overview}>
        <div>
          <Typography variant="caption" className="mr-3">
            Versión Beta
          </Typography>
        </div>
        <Box display="flex" alignItems="center">
          <img
            alt="pti"
            style={{ height: '40px', width: '184px' }}
            src={MSP}
            className="mr-3"
          />
          <img
            alt="pti"
            style={{ height: '40px', width: '64px' }}
            src={PTI}
            className="mr-3"
          />
        </Box>
      </Box>
    </div>
  );
};
